:root {
  --primaryColor: #14d5c2;
  /* Main */
  --primaryColorLight: #14d5c275;
  /* Tint */
  --secondaryColor: #16786c;
  /* Accent */
  --secondaryColorLight: #16786c75;
  --coloredText: #F2E6C4;
  --accentTextColor: #FAF6E8;
  --summaryBgBlockColor: #1e3b48;
  --progressBg: #2D5668;
  --primaryText: #2d2d2d;
  /* Font */
  --mainBg: #1e3b48;
  /* Background */
  --primaryTextLight: rgba(255, 255, 255, 0.6);
  --manageBorderColor: rgba(255, 255, 255, 0.1);
  --secondaryFontFamily: "Philosopher", sans-serif;
  --boxShadow: none;
  --titleText: #6a52ff;
  --secondaryText: white;
  --questionHighlightTitleColor: var(--titleH1Color);
  --answerTitleTextAlign: center;
  --secondaryBg: #2D5668;
  --questionTitleColor: #FAF6E8;
  --questionActiveTitleColor: #f2e6c4;
  --questionTitleImageColor: #10161E;
  /* Quetions */
  --feedbackStarColor: var(--primaryColor);
  --injectedPaymentPrimaryColor: #499b92;
  --headerBgColor: #1E3B48;

  /* special offer */
  --soDiscountBlockBg: #1E3B48;
  --soDiscountImageBg: #142B35;

  --soReservedPriceBg: var(--primaryColor);

  --soInfoAccentTextColor: var(--primaryColor);
  --soInfoOfferTextBg: #F3EEFF;

  --emailInputBackgroundColor: transparent;

  --payModalPrimaryColor: #16786c;

  --backgroundBlockColor: var(--mainBg);
  --upsaleButtonsBg: #142b35;

  --autocompleteEmailTextColor: var(--secondaryText);
  --emailInputColor: var(--secondaryText);
  --emailInputBorderColor: var(--primaryColor);

  --progressTextLight: rgba(208, 184, 148, 0.40);

  /*face reading */
  --faceReadingBgColor: var(--mainBg);
  --faceReadingTextColor: var(--coloredText);
  --faceReadingProgressNumberColor: var(--coloredText);
  --faceReadingAnalyzingTextColor: #FAF6E8;
  --faceReadingButtonColor: #fff;
  --faceReadingAnalyzingAnimationColor: linear-gradient(0deg, rgba(24, 207, 188, 0.90) 0%, rgba(22, 209, 190, 0.00) 100%);
  --faceReadingUploadTitleColor: var(--coloredText);
  --faceReadingUploadTextColor: #FAF6E8;
  --faceReadingUploadCloseIconColor: var(--coloredText);


  --highlightColorText: #00EEDB;

  --selectionsHintFontStyle: normal;
  --selectionsHintColor: rgba(208, 184, 148, #d0b894);
  --selectionsHintOpacity: 1;

  --errorPopupPrimaryColor: var(--primaryColor);
  --errorPopupBgColor: var(--secondaryBg);
  --errorPopupTitleColor: var(--coloredText);
  --errorPopupSubtitleColor: #ffffffd9;

  /* create account */
  --createAccountSuccessPopupBg: var(--secondaryBg);
  --createAccountSuccessPopupSubtitle: #ffffffd9;
}

:root[data-theme='light'] {
  --primaryColor: #4651EF;
  --secondaryColor: #FF69C3;

  --fontFamily: "Open Sans", sans-serif;
  --secondaryFontFamily: "Crimson Pro", serif;
  --landingBg: #F2F2F2;
  --coloredText: var(--primaryText);

  --summaryBgBlockColor: #FFFFFF;
  --summarySubtitle: var(--primaryText);
  --summaryText: var(--primaryText);
  --summaryZodiacIconColor: #F2F2F2;
  --fixedButtonBoxBg: #FFFFFF;
  --fixedButtonDiscountColor: var(--primaryText);

  --headerBgColor: #FFFFFF;

  --primaryText: #10161E;
  --secondaryText: #53575D;
  --textColor: var(--secondaryText);
  --secondaryBg: white;
  --questionTitleColor: #10161E;
  --questionTitleImageColor: white;
  --questionActiveTitleBorderColor: #4651EF;
  --questionActiveTitleColor: #4651EF;
  --questionActiveCheckmarkColor: #FF69C3;
  --questionCheckmarkIconColor: white;
  --questionCheckmarkBg: var(--mainBg);
  --questionActiveTitleBackgroundColor: #ebecff;
  --answerTitleTextAlign: left;
  --questionHighlightTitleColor: var(--primaryColor);
  --questionTitleFontWeight: 500;
  --progressBg: #f1f1f1;
  --plansSectionTitle: var(--primaryText);
  --policyCheckBoxTextColor: var(--primaryText);
  --paymentSafeColor: var(--primaryText);

  --accentTextColor: var(--primaryColor);

  --titleH1Color: var(--primaryText);
  --titleH2Color: var(--primaryText);
  --titleH3Color: var(--primaryText);
  --titleH1FontFamily: var(--secondaryFontFamily);
  --titleH2FontFamily: var(--secondaryFontFamily);
  --titleH3FontFamily: var(--secondaryFontFamily);

  --appTitleColor: var(--primaryText);

  --mainBg: #F2F2F2;
  --backgroundBlockColor: var(--mainBg);

  --buttonBackground: var(--primaryColor);
  --buttonFontFamily: "Open Sans", sans-serif;
  --buttonFontSize: 20px;
  --buttonBorderRadius: 15px;

  --preloaderColor: var(--primaryColor);
  --preloaderBg: var(--mainBg);
  --preloaderTextColor: var(--primaryText);

  --progressPrevIconColor: var(--primaryText);
  --progressStateCountColor: var(--secondaryText);
  --progressFilledLineColor: var(--primaryColor);
  --progressLineColor: #DBDCDD;

  --logoFontSize: 20px;

  --footerTextColor: var(--secondaryText);

  --engVersionPrimary: var(--secondaryText);

  --inputBorder: #BFBFBF;
  --inputBgColor: #FFFFFF;
  --inputWrapperBgColor: #F2F2F2;
  --inputPlaceholderColor: #BFBFBF;
  --inputTextColor: var(--primaryText);

  --usersJoinedBorder: transparent;
  --usersJoinedTextColor: var(--primaryText);
  --usersJoinedBoldColor: var(--primaryText);
  --usersJoinedAvaBorderColor: #FFFFFF;

  /* email page */
  --emailPageBg: #FFFFFF;
  --emailInputBackgroundColor: white;
  --emailNotificationColor: var(--primaryColor);
  --mailCheckboxTextColor: var(--primaryText);
  --mailCheckboxBorderColor: var(--primaryColor);
  --checkboxMarkCheckedBackground: var(--primaryColor);
  --emailDividerTextColor: var(--primaryColor);
  --emailDividerLineColor: var(--primaryColor);

  /* autocomplete  */
  --autocompleteBgColor: #FFFFFF;
  --autocompleteTextColor: var(--primaryText);
  --autocompleteBorderColor: var(--inputBorder);

  /* success page */
  --successPagePrimaryTextColor: #10161ee6;
  --successPageSecondaryTextColor: var(--primaryText);
  --successPageBtnDividerLineColor: #B8B9C366;
  --successPageContentBoxBgColor: #fff;
  --successPageBg: var(--mainBg);

  /* payment modal */
  --payModalPrimaryColor: var(--primaryColor);
  --paymentSubtitleColor: #666fe8;
  --paymentModalLineThroughPriceOpacity: 1;
  --injectedPaymentCheckBgColor: #E3E5FD;
  --injectedPaymentPrimaryColor: var(--primaryColor);

  --paymentActionModalBgColor: #fff;
  --paymentActionModalOverlayBgColor: #00000066;
  --paymentActionModalTextColor: #10161ee6;

  /* subscription */
  --plansPrimaryColor: var(--primaryColor);
  --plansBg: #FFFFFF;
  --plansInactiveBg: var(--plansBg);
  --plansTicketInactiveBgColor: #F2F2F2;
  --plansBadgeColor: var(--secondaryColor);
  --plansTicketInactiveTextColor: var(--primaryText);
  --plansTicketInactiveAccentTitleColor: var(--primaryText);
  --plansTicketInactiveSubTitleColor: #10161E80;
  --plansTicketActiveSubTitleColor: #FFFFFFB3;
  --plansTicketCheckBoxBorderColor: var(--primaryText);

  /* trials left */
  --trialsLeftBadgeBg: var(--secondaryColor);

  /* palm reading */
  --analyzingPalmReadingBgColor: var(--mainBg);
  --uploadPalmReadingBgColor: #FFF;

  --palmReadingTextColor: var(--primaryText);

  --palmReadingPrivacyTextColor: #10161ecc;
  --palmReadingDisclaimerColor: var(--primaryText);

  --palmReadingScanAnimBg: linear-gradient(0deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0) 100%);

  --palmReadingPhotoInnerBgColor: var(--primaryColor);
  --palmReadingPhotoOuterBgColor: var(--primaryColor);

  --palmReadingProgressColor: var(--primaryColor);
  --palmReadingProgressPadding: 0;
  --palmReadingProgressBgColor: #10161e1a;
  --palmReadingProgressTextColor: var(--primaryColor);

  /* error modal */
  --errorModalBgColor: #FFF;
  --errorModalCloseBtnBgColor: var(--primaryColor);

  --errorModalSkipBtnColor: var(--primaryColor);
  /* upsales */
  --upsaleBgColor: var(--mainBg);
  --upsaleButtonsBg: var(--mainBg);
  --upsaleProgressMainColor: var(--primaryColor);
  --upsaleProgressSecondLineColor: #aaabac;
  --upsaleProgressPointBgColor: var(--mainBg);
  --upsaleProgressInactiveColor: var(--secondaryText);
  --upsaleProgressPointBorderColor: var(--mainBg);

  --upsaleListPointBgColor: var(--primaryColor);
  --upsaleListTextColor: var(--primaryText);

  --upsaleActivePriceColor: var(--primaryText);
  --upsaleNotActivePriceColor: var(--secondaryText);
  --upsaleNotActivePriceDecorationColor: var(--primaryColor);

  --upsaleSkipTextColor: var(--primaryColor);
  --upsaleFootnoteTextColor: var(--secondaryText);

  --engVersionPrimary: var(--secondaryText);

  /* contact us modal */
  --contactUsModalBg: #FFFFFF;
  --contactUsModalTitleColor: var(--primaryText);
  --contactUsModalSubTitleColor: #10161EE6;
  --contactUsModalFieldColor: var(--primaryColor);
  --contactUsModalActiveColor: #e4b6ef;
  --contactUsModalSecondaryBg: var(--mainBg);
  --contactUsModalTextColor: #10161EE6;
  --contactUsModalDoneIcon: var(--contactUsModalBg);

  /* my account */
  --myAccountBg: var(--mainBg);
  --myAccountTitleColor: var(--primaryText);
  --myAccountSubTitleColor: #10161EE6;
  --myAccountTextColor: #10161EB3;
  --myAccountHighlightTextColor: #10161E;
  --myAccountInfoBlockBg: #FFFFFF;


  --sideBarDarkBackDropBg: rgba(47, 47, 47, 0.8);
}

:root[data-theme='dark'] {
  --primaryColor: #5671FF;
  --secondaryColor: #61EAF3;

  --fontFamily: "Open Sans", sans-serif;
  --secondaryFontFamily: "Crimson Pro", serif;
  --landingBg: #10161E;
  --coloredText: var(--primaryText);
  --boxShadow: none;

  --summaryBgBlockColor: #283547;
  --summarySubtitle: var(--primaryText);
  --summaryText: var(--primaryText);
  --summaryZodiacIconColor: #3B516E;
  --fixedButtonBoxBg: #283547;
  --fixedButtonDiscountColor: var(--primaryText);

  --headerBgColor: #283547;

  --primaryText: #F4F8FF;
  --secondaryText: #C6CBD2;
  --textColor: var(--secondaryText);
  --secondaryBg: var(--mainBg);
  --questionTitleColor: var(--primaryText);
  --questionTitleImageColor: white;
  --questionCheckmarkIconColor: var(--mainBg);
  --questionCheckmarkBg: #283547;
  --questionBorderColor: #3B516E;
  --questionAnswerBorderColor: #3B516E;
  --questionSubTitleColor: var(--secondaryText);
  --questionActiveCheckmarkColor: var(--secondaryColor);
  --questionActiveTitleBorderColor: var(--secondaryColor);
  --questionActiveTitleColor: var(--secondaryColor);
  --questionActiveTitleBackgroundColor: var(--mainBg);
  --answerTitleTextAlign: left;
  --questionHighlightTitleColor: var(--secondaryColor);
  --questionTitleFontWeight: 500;
  --progressBg: #3C4859;
  --plansSectionTitle: var(--primaryText);
  --policyCheckBoxTextColor: var(--primaryText);
  --paymentSafeColor: var(--primaryText);

  --accentTextColor: var(--secondaryColor);

  --titleH1Color: var(--primaryText);
  --titleH2Color: var(--primaryText);
  --titleH3Color: var(--primaryText);
  --titleH1FontFamily: var(--secondaryFontFamily);
  --titleH2FontFamily: var(--secondaryFontFamily);
  --titleH3FontFamily: var(--secondaryFontFamily);

  --appTitleColor: #C6CBD2;

  --gradientBg: linear-gradient(198deg, #172362 0%, #131A24 43.33%, var(--mainBg) 100%), var(--mainBg);
  ;
  --mainBg: #111827;
  --backgroundBlockColor: var(--mainBg);

  --buttonBackground: var(--primaryColor);
  --buttonFontFamily: "Open Sans", sans-serif;
  --buttonFontSize: 20px;
  --buttonBorderRadius: 15px;

  --preloaderColor: var(--primaryColor);
  --preloaderBg: var(--mainBg);
  --preloaderTextColor: var(--primaryText);

  --progressPrevIconColor: var(--primaryText);
  --progressStateCountColor: var(--secondaryText);
  --progressFilledLineColor: var(--primaryColor);
  --progressLineColor: rgba(255, 255, 255, 0.10);

  --logoFontSize: 20px;

  --footerTextColor: var(--primaryText);

  --inputBorder: #607EA8;
  --inputBgColor: #111827;
  --inputWrapperBgColor: var(--mainBg);
  --inputPlaceholderColor: #BFBFBF;
  --inputTextColor: var(--primaryText);

  --usersJoinedBorder: transparent;
  --usersJoinedTextColor: var(--primaryText);
  --usersJoinedBoldColor: var(--primaryText);
  --usersJoinedAvaBorderColor: #FFFFFF;

  /* email page */
  --emailPageBg: #283547;
  --emailNotificationColor: var(--secondaryText);
  --emailInputBackgroundColor: #283547;
  --emailInputBorderColor: var(--secondaryColor);
  --emailInputColor: var(--primaryText);
  --mailCheckboxTextColor: var(--primaryText);
  --mailCheckboxBorderColor: var(--primaryColor);
  --checkboxMarkCheckedBackground: var(--primaryColor);
  --emailDividerTextColor: var(--primaryColor);
  --emailDividerLineColor: var(--primaryColor);

  /* autocomplete  */
  --autocompleteBgColor: #3B516E;
  --autocompleteTextColor: var(--primaryText);
  --autocompleteBorderColor: var(--inputBorder);

  /* success page */
  --successThankPageText: #C6CBD2;
  --successPageBtnDividerLineColor: #3B516E;
  --successPageBtnDividerTextColor: #999EA5;
  --successPageBg: var(--gradientBg);
  --successPageContentBoxBgColor: #283547;

  /* payment modal */
  --payModalPrimaryColor: var(--primaryColor);
  --paymentSubtitleColor: #666fe8;
  --paymentModalLineThroughPriceOpacity: 1;
  --paymentModalInputTextColor: #2d2d2d;
  --injectedPaymentCheckBgColor: #E3E5FD;
  --injectedPaymentPrimaryColor: var(--primaryColor);

  --paymentActionModalBgColor: #283547;
  --paymentActionModalOverlayBgColor: #00000066;
  --paymentActionModalTextColor: #C6CBD2E6;

  /* subscription */
  --plansPrimaryColor: var(--primaryColor);
  --plansBg: #10161E;
  --plansTicketInactiveBgColor: #3B516E;
  --plansInactiveBg: #283547;
  --plansBadgeColor: var(--secondaryColor);
  --plansTicketInactiveTextColor: var(--primaryText);
  --plansTicketInactiveAccentTitleColor: var(--primaryText);
  --plansTicketInactiveSubTitleColor: #F4F8FF;
  --plansTicketActiveSubTitleColor: #FFFFFFB3;
  --plansTicketCheckBoxBorderColor: #4D6B94;
  --plansDiscountBgColor: var(--secondaryColor);
  --plansDiscountTextColor: #10161E;

  /* trials left */
  --trialsLeftBadgeBg: var(--secondaryColor);
  --trialsLeftBadgeColor: #10161E;

  /* palm reading */
  --analyzingPalmReadingBgColor: var(--mainBg);
  --uploadPalmReadingBgColor: #283547;

  --palmReadingTextColor: var(--primaryText);

  --palmReadingPrivacyTextColor: #f4f8ffcc;
  --palmReadingDisclaimerColor: var(--primaryText);

  --palmReadingScanAnimBg: linear-gradient(0deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0) 100%);

  --palmReadingPhotoInnerBgColor: var(--primaryColor);
  --palmReadingPhotoOuterBgColor: var(--primaryColor);

  --palmReadingProgressColor: var(--primaryColor);
  --palmReadingProgressPadding: 0;
  --palmReadingProgressBgColor: #233041;
  --palmReadingProgressTextColor: var(--primaryColor);

  /* error modal */
  --errorModalBgColor: #283547;
  --errorModalCloseBtnBgColor: var(--primaryColor);

  --errorModalSkipBtnColor: var(--primaryColor);
  /* upsales */
  --upsaleBgColor: var(--gradientBg);
  --upsaleButtonsBg: var(--mainBg);

  --upsaleProgressMainColor: var(--primaryColor);
  --upsaleProgressSecondLineColor: #999EA5;
  --upsaleProgressLineOpacity: 1;
  --upsaleProgressPointBgColor: var(--mainBg);
  --upsaleProgressInactiveColor: #999EA5;
  --upsaleProgressPointBorderColor: #F4F8FF;

  --upsaleListPointBgColor: var(--secondaryColor);
  --upsaleListTextColor: var(--primaryText);

  --upsaleActivePriceColor: var(--primaryText);
  --upsaleNotActivePriceColor: #C6CBD2;
  --upsaleNotActivePriceDecorationColor: #FFF;

  --upsaleSkipTextColor: var(--primaryColor);
  --upsaleFootnoteTextColor: #999EA5;

  --engVersionPrimary: #C6CBD2;

  /* terms menu */
  --sideBarDarkButtonColor: #C6CBD2;
  --sideBarDarkBgColor: #0d1539;
  --sideBarDarkBackDropBg: #131A24B3;


  /* contact us modal */
  --contactUsModalBg: #283547;
  --contactUsModalTitleColor: var(--primaryText);
  --contactUsModalSubTitleColor: #C6CBD2E6;
  --contactUsModalFieldColor: var(--primaryColor);
  --contactUsModalActiveColor: #62e9f3;
  --contactUsModalSecondaryBg: var(--mainBg);
  --contactUsModalTextColor: #C6CBD2E6;
  --contactUsModalDoneIcon: var(--primaryText);

  /* my account */
  --myAccountBg: var(--mainBg);
  --myAccountTitleColor: var(--primaryText);
  --myAccountSubTitleColor: #C6CBD2E6;
  --myAccountTextColor: #C6CBD2E6;
  --myAccountInfoBlockBg: #283547;
}