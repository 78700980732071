@import "src/styles";

.root {
  display: flex;
  width: 100vw;
  height: 100%;
  background: $backgroundBlockColor;
  flex-direction: column;
  flex-basis: 100%;
  position: relative;
  overflow: hidden;

  &.chatflow {
    background-color: transparent;
    background-image: image-set("@web-solutions/chat-flow/src/components/images/BG.webp" 1x, "@web-solutions/chat-flow/src/components/images/BG@2x.webp" 2x, "@web-solutions/chat-flow/src/components/images/BG@3x.webp" 3x);

    .footer {
      .instruction {
        color: $chatFlowTextSecondaryColor;
      }

      button {
        border-color: $chatFlowLoaderColor;
        span {
          background: $chatFlowLoaderColor;
        }
      }
    }
  }

  .back {
    position: absolute;
    width: 40px;
    height: 40px;
    left: 10px;
    top: 10px;
    background-repeat: no-repeat;
    background-size: 24px 13px;
    background-position: center;
    background-image: image-set("images/back.webp" 1x,
        "images/back@2x.webp" 2x,
        "images/back@3x.webp" 3x);
    z-index: 10;

    @include map-styles($palmReadingBackButtonStyles);
  }
}

.canvas {
  position: absolute;
  flex-basis: 0%;
  transform-origin: left top;
}

.video {
  display: none;
  position: absolute;
}

.warning {
  position: absolute;
  top: 10%;
  width: 90%;
  left: 5%;
  background: $backgroundBlockColor;
  border-radius: 8px;
  padding: 8px 16px;
  color: $palmReadingTextColor;
  font-size: 90%;
  text-align: center;
}

.shape {
  position: relative;
  width: 100%;
  height: 80%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: image-set("./images/shape.webp" 1x,
      "./images/shape@2x.webp" 2x,
      "./images/shape@3x.webp" 3x);
  background-size: cover;
  background-position: center;
  display: flex;

  .point {
    width: 4px;
    height: 4px;
    border-radius: 2px;
    background: red;
    position: absolute;
  }
}

.footer {
  display: flex;
  flex-basis: 20%;
  z-index: 100;
  width: 100%;
  flex-direction: column;

  .instruction {
    color: $palmReadingTextColor;
    text-align: center;
    margin: 2vh;
    font-size: $fontSize14;
    max-height: 4vh;
  }

  button {
    margin: auto;
    width: 11vh;
    height: 11vh;
    border-radius: 50%;
    border: 5px solid;
    border-color: $palmReadingPhotoOuterBgColor;
    display: flex;
    padding: 0;

    span {
      display: block;
      width: calc(100% - 8px);
      height: calc(100% - 8px);
      margin: auto;
      background: $palmReadingPhotoInnerBgColor;
      border-radius: 50%;
      border: 4px solid transparent;
    }
  }
}