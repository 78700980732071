@import 'src/styles';

@mixin line($color, $width) {
  content: '';
  position: absolute;
  top: 8px;
  height: 1px;
  background-color: $color;
  width: $width;
}

.divider {
  width: 100%;
  font-size: $fontSize14;
  font-weight: $fwn;
  position: relative;
  margin: 15px 0;
  text-align: center;
  color: $createAccountDividerColor;

  &:before {
    @include line($createAccountDividerColor, 25%);
    right: 0;
  }

  &:after {
    @include line($createAccountDividerColor, 25%);
    left: 0;
  }

  &.for_email {
    color: $mineShaft;

    &:before {
      @include line($mineShaft, 45%);
      right: 0;
    }

    &:after {
      @include line($mineShaft, 45%);
      left: 0;
    }
  }

}