@import "src/styles";

.img {
  display: flex;
  background: #226165;
  border-radius: 16px;
  padding: 20px;
  margin-bottom: 20px;

  &.without_bg {
    padding: 0;
    background: transparent;
  }
}