@import "src/styles";

.root {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  transform-origin: left top;

  .konva {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 800ms;
  }
  .point {
    border-radius: 4px;
    background: white;
    position: absolute;
    width: 8px;
    height: 8px;
    box-sizing: border-box;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 800ms;
  }

  .visible {
    opacity: 1;
  }
}
