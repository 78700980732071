@import "src/styles";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: $analyzingPalmReadingBgColor;
}

.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.photo {
  background: #d8d8d8;
  border-radius: 15px;
  margin: auto;
  width: 100%;
}

.progress {
  &__wrapper {
    position: relative;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    min-height: 140px;
    padding-top: 4px;
    background-color: $analyzingPalmReadingBgColor;
  }

  &__bg {
    @include map-styles($palmReadingBottomBgStyles);
  }

  &__number {
    z-index: 1;
    margin-bottom: 15px;
    font-weight: $fwb;
    font-size: $fontSize32;
    line-height: 1.1;

    @include map-styles($palmReadingProgressTextStyles);
  }

  &__line {
    &_inactive {
      position: relative;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: $palmReadingProgressPadding;
      width: 90%;
      max-width: 274px;
      background: $palmReadingProgressBgColor;
      border-radius: 8px;
      overflow: hidden;
    }

    &_active {
      z-index: 1;
      height: 10px;
      background: $palmReadingProgressColor;
      border-radius: 8px;
    }

    &_animated {
      width: 25%;
      animation: infiniteLoader linear 1.5s;
      animation-iteration-count: infinite;
    }
  }

  &__titles {
    margin-top: 15px;
    z-index: 1;
    position: relative;
    width: 80%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    text-align: center;
  }

  &__title {
    position: absolute;
    top: 0;
    font-weight: 700;
    font-size: $fontSize20;
    line-height: 1.1;
    opacity: 0;
    color: $palmReadingAnalyzingTextColor;

    &_active {
      animation: fadeInTitle ease 2s;
      opacity: 1;
    }
  }
}

@keyframes fadeInTitle {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes infiniteLoader {
  0% {
    translate: -100%
  }

  50% {
    translate: 400%
  }

  100% {
    translate: -100%
  }
}