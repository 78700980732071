@import "src/styles/index";

.overlay {
  position: fixed;
  z-index: 1000;
  inset: 0;
  background: rgba(21, 43, 53, 0.8);

  .modal {
    background: $errorModalBgColor;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 90%;
    max-width: 315px;
    z-index: 1001;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 30px;

    .title {
      font-style: normal;
      font-weight: 700;
      font-size: $fontSize22;
      line-height: $fontSize30;
      margin-bottom: 30px;
      white-space: pre-line;

      font-family: $errorModalFontFamily;
      color: $errorModalTitleColor;
    }

    .button {
      background: $errorModalCloseBtnBgColor;
      border-radius: 34px;
      display: flex;
      align-items: center;
      justify-content: center;

      &__title {
        font-weight: 700;
        font-size: $fontSize22;
        line-height: 1.2;

        font-family: $errorModalFontFamily;
      }
    }

    .skip {
      color: $errorModalSkipBtnColor;
      font-weight: $fwb;
      margin-top: 16px;
      text-decoration: none;
    }
  }
}