@import "src/styles";

.footer {
  position: absolute;
  width: 100%;
  height: 150px;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .back {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
    text-decoration: none;
    font-weight: $fwb;
  }

  .icon {
    margin-right: 5px;
    width: 18px;
    height: 18px;
    min-width: 18px;
  }
}