@import 'src/styles';

.popup {
  position: absolute;
  width: 100%;
  min-height: 450px;
  bottom: 0;
  border-radius: 30px 30px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  padding: 20px;

  .image {
    margin-bottom: 15px;
    margin-top: 10%;
  }

  .title {
    font-weight: 700;
    font-size: $fontSize26;
    line-height: 1.23;
    color: $palmReadingTextColor;
  }

  .text {
    margin-top: 15px;
    max-width: 325px;
    font-size: $fontSize17;
    text-align: center;
    line-height: 1.4;
    opacity: 0.85;
    color: $palmReadingTextColor;
  }

  .content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .input {
    display: none;
  }

  .canvas {
    display: none;
  }

  .button {
    margin-top: 30px;
  }
}