@import 'src/styles';

.upload {
  width: 100%;
  background-image: image-set('images/bg.webp' 1x, 'images/bg@2x.webp' 2x, 'images/bg@3x.webp' 3x);
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: -30px;

  .back {
    position: absolute;
    width: 40px;
    height: 40px;
    left: 10px;
    top: 10px;
    background-repeat: no-repeat;
    background-size: 24px 13px;
    background-position: center;
    background-image: image-set(
      "../capture/images/back.webp" 1x,
      "../capture/images/back@2x.webp" 2x,
      "../capture/images/back@3x.webp" 3x
    );
    z-index: 10;
    @include map-styles($palmReadingBackButtonStyles);
  }

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(21, 43, 53, 0.8);
  }
}
