@import "src/styles";

.wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  background: $backgroundBlockColor;
}