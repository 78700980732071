@import "src/styles";

.container {
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.animation {
  position: absolute;
  z-index: 2;
  animation-name: fadeInAnimation;
  animation-iteration-count: infinite;
  animation-duration: 4000ms;
  animation-timing-function: linear;
  width: 100%;
  height: 20%;
  top: -20%;
  background: $palmReadingScanAnimBg;
  transform: rotateY(180);
}

@keyframes fadeInAnimation {
  0% {
    top: -30%;
    transform: rotateX(0deg);
  }

  50% {
    top: 90%;
    transform: rotateX(0deg);
  }

  50.1% {
    top: 90%;
    transform: rotateX(180deg);
  }

  100% {
    top: -30%;
    transform: rotateX(180deg);
  }
}