@import "core/styles";

// colors
$gold: #d0b894;
$secondDarkGreen: #142b35;
$lightNeonGreen: #00eedb;
$semiNeonGreen: #14d5c2;
$darkNeonGreen: #27baa7;
$lightGreen: #3d7481;
$thirdDarkGreen: #1e3b48;
$deepDarkGreen: #142b35;
$darkTeal: #2E897F;
$darkTeal: #2E897F;

$secondaryGray: #c7c7c7;
$oldLace: #fdeee4;
$mystic: #d9eae8;
$lightGreenBg: #e3f1ee;
$light-beige: #fef4ef;
$lightRed: #f9e2d6;
$lightBlue: #d8e9ef;
$secondLightBlue: #f2fbfa;
$beige: #f2f2f2;
$grayLight: #e0e0e0;
$lightSand: #f2e6c4;
$rumSwizzle: #faf6e8;
$goldSand: #a39376;
$genoa: #16786c;
$breakerBay: #69a79b;
$redDamask: #dc7f4b;
$darkRed: #e88c5b;
$apricot: #ee8f61;
$red: #eda882;
$mineShaft: #2d2d2d;
$scorpion: #565656;
$darkGrayBlue: #335662;
$darkGrayBlue: #335662;
$darkDeepGreen: #1e3b48;
$cocoa: #785643;
$romanCoffee: #83614c;
$graySpecial: #575757;
$graySpecialTwo: #797979;
$gray: #818181;
$lightCocoa: #c0afa1;
$dark-beige: #c7b8ab;
$eggWhite: #fff2c4;
$lightYellow: #fef7db;
$darkPowder: #fcece3;
$green: #bed7d4;
$cloudyBlue: #b9d7d1;
$darkGreen: #a6c8c4;
$blue: #7eb6c9;
$darkBlue: #3e89a2;
$yellow: #fde387;
$darkYellow: #e8ca61;
$blackColor: #000000;
$ocean: #376478;
$lightFire: #f27067;
$lightFireLight: #f2706775;
$middleGray: #4b626d;
$lowGray: #2d5668;
$breeze: #45d5c1;
$darkBreeze: #298784;
$lightBreeze: #7da9a2;
$mintJulep: #f2e6c4;
$trans: transparent;

$primaryText: var(--primaryText, #333333);
$accentTextColor: var(--accentTextColor, $lightSand);

$engVersionPrimary: var(--engVersionPrimary, $mintJulep);

$appTitleColor: var(--appTitleColor, $lightSand);
$paymentDisclaimerColor: rgba(250, 246, 232, 0.80);

// email page
$emailPageBg: var(--emailPageBg, $lowGray);
$emailInputBackgroundColor: var(--emailInputBackgroundColor, $white);
$emailInputBorderColor: var(--emailInputBorderColor, var(--primaryColor));
$emailInputColor: var(--emailInputColor, $mineShaft);
$emailNotificationColor: var(--emailNotificationColor, rgba($white, 0.6));
$emailNotificationIconColor: var(--emailNotificationIconColor, var(--emailNotificationColor, rgba($white, 0.6)));
$mailCheckboxTextColor: var(--mailCheckboxTextColor, $white);
$mailCheckboxBorderColor: var(--mailCheckboxBorderColor, $white);
$emailDividerTextColor: var(--emailDividerTextColor, $lightSand);
$emailDividerLineColor: var(--emailDividerLineColor, linear-gradient(270deg,
      rgba(242, 230, 196, 0.8) 0%,
      rgba(242, 230, 196, 0) 100%));

//summary
$summaryTitle: $gold;
$summarySubtitle: var(--summarySubtitle, $lightSand);
$summaryBgBlockColor: var(--summaryBgBlockColor, $semiNeonGreen);
$summaryBgActiveButtonColor: $ocean;
$summaryBgButtonColor: $darkGreen;
$summaryBgDiscount: $lightFire;
$summaryBgPerWeekButton: $middleGray;
$summaryText: var(--summaryText, $goldSand);
$summaryZodiacIconColor: var(--summaryZodiacIconColor, $lowGray);
$summaryTerminateInputColor: $darkBreeze;
$summaryTerminateButton: $breeze;
$summaryTerminateLogo: $lightBreeze;

// preloader
$preloaderColor: var(--preloaderColor, #16786c);
$preloaderBg: var(--preloaderBg, #142b3577);
$preloaderTextColor: var(--preloaderTextColor, $rumSwizzle);

//shadow & opacity
$blackWithOpacity: rgba(0, 0, 0, 0.5);
$grayOpacity: rgba(45, 45, 45, 0.4);
$boxShadowColor: rgba(183, 181, 170, 0.4);
$boxShadow: var(--boxShadow, 0 1px 4px 0 rgba(183, 181, 170, 0.4));
$boxShadowTitle: 0 1px 2px rgba(0, 0, 0, 0.2);
$shadowColor: rgba(183, 181, 170, 0.3);

// goal component
$firstGoalBg: $lightGreenBg;
$firstLabelBg: $green;
$firstLabelArcBg: $darkGreen;
$secondGoalBg: $lightRed;
$secondLabelBg: $red;
$secondLabelArcBg: $darkRed;
$thirdGoalBg: $lightBlue;
$thirdLabelBg: $blue;
$thirdLabelArcBg: $darkBlue;
$fourthGoalBg: $lightYellow;
$fourthLabelBg: $yellow;
$fourthLabelArcBg: $darkYellow;

// base
$mainBgColor: var(--mainBg, $darkGreen);
$inactiveColor: $secondDarkGreen;
$activeColor: $darkNeonGreen;
$backgroundBlockColor: var(--backgroundBlockColor, $thirdDarkGreen);
$coloredText: var(--coloredText, #F2E6C4);

$accentTextColor: var(--accentTextColor, $lightSand);

// buttons
$buttonLightBgColor: $lightNeonGreen;
$buttonDarkBgColor: $darkNeonGreen;
$disabledButtonBg: $oldLace;
$disabledButtonColor: $dark-beige;
$disabledEmailButtonBg: $light-beige;
$disabledEmailButtonColor: $lightCocoa;
$buttonWrapperBg: $secondLightBlue;

// input
$inputTextColor: var(--inputTextColor, $rumSwizzle);
$inputPlaceholderColor: var(--inputPlaceholderColor, rgba($rumSwizzle, 0.5));
$inputBorder: var(--inputBorder, $semiNeonGreen);
$inputWrapperBgColor: var(--inputWrapperBgColor, $thirdDarkGreen);
$inputBgColor: var(--inputBgColor, transparent);

// select
$selectInputTextColor: $rumSwizzle;
$selectInputBgColor: var(--inputBgColor, transparent);
$selectInputBorder: var(--inputBorder, $semiNeonGreen);

// links
$linkColor: #2879d8;

// form
$colorLabel: #333333;
$colorFocus: #2879d8;
$colorTextField: #333333;
$colorPlaceholder: #dbdbdb;
$colorBorderField: rgba(184, 185, 195, 0.3);
$colorBgSuccesMessage: #f2ffe5;

$inputColor: $secondaryGray;
$inputCaretColor: $genoa;

$checkboxMarkBackground: var(--checkboxMarkCheckedBackground, var(--checkboxMarkCheckedBackground, $darkNeonGreen));
$checkboxMarkCheckedBackground: var(--checkboxMarkCheckedBackground, var(--checkboxMarkCheckedBackground, $darkNeonGreen)); // TODO

// button
$buttonBackground: var(--buttonBackground,
    linear-gradient(2.59deg,
      $buttonLightBgColor -78%,
      $buttonDarkBgColor 145%));
$buttonFontFamily: var(--buttonFontFamily, $fontFamilyPhilosopher);
$buttonFontSize: var(--buttonFontSize, $fontSize24);
$buttonFontWeight: $fwstb;
$buttonBorderRadius: var(--buttonBorderRadius, 50px);
$buttonBorderRadius: var(--buttonBorderRadius, 50px);

//payment
$payModalPrimaryColor: var(--payModalPrimaryColor, #16786c);
$paymentSubtitleColor: var(--paymentSubtitleColor, #27BAA7);
$paymentIconTimerColor: var(--paymentSubtitleColor, #14D5C2);
$paymentModalLineThroughPriceColor: var(--payModalPrimaryColor, inherit);
$paymentModalLineThroughPriceOpacity: var(--paymentModalLineThroughPriceOpacity, 0.7);
$paymentModalInputTextColor: var(--paymentModalInputTextColor, $primaryText);

$paymentActionModalBgColor: var(--paymentActionModalBgColor, #2D5668);
$paymentActionModalOverlayBgColor: var(--paymentActionModalOverlayBgColor, rgba(21, 43, 53, 0.8));
$paymentActionModalTextColor: var(--paymentActionModalTextColor, rgba(255, 255, 255, 0.85));

// title
$titleH1Color: var(--titleH1Color, $lightSand);
$titleH1FontFamily: var(--titleH1FontFamily, $fontFamilyPhilosopher);
$titleH2Color: var(--titleH2Color, $lightSand);
$titleH2FontFamily: var(--titleH1FontFamily, $fontFamilyPhilosopher);
$titleH3Color: var(--titleH3Color, $gold);
$titleH3FontFamily: var(--titleH1FontFamily, $fontFamilyPhilosopher);
$textColor: var(--textColor, rgba($rumSwizzle, 0.8));

// subscription
$plansSectionTitle: var(--plansSectionTitle, #f2e6c4);
$plansPrimaryBg: var(--plansBg, #2b5567);
$plansInactiveBg: var(--plansInactiveBg, $darkDeepGreen);
$plansPrimaryColor: var(--plansPrimaryColor, $darkNeonGreen);
$plansInactiveColor: var(--plansTicketInactiveBgColor, $middleGray);
$plansDiscountTextColor: var(--plansDiscountTextColor, $white);
$plansPrimaryBorderColor: var(--plansPrimaryBorderColor, $plansPrimaryColor);
$plansDiscountBgColor: var(--plansDiscountBgColor, $plansPrimaryBorderColor);
$plansInactiveBorderColor: $ocean;
$plansPrimaryTitleColor: $titleH1Color;
$plansInactiveTitleColor: $plansInactiveColor;
$plansPrimaryTextColor: white;
$plansInactiveTextColor: $plansInactiveColor;
$plansPrimaryPriceColor: $white;
$plansInactivePriceColor: transparentize($white, 0.2);
$plansBadgeColor: var(--plansBadgeColor, $lightFire);
$plansBadgeColorLight: $lightFireLight;
$plansActiveAfterTrialTextColor: $plansPrimaryTextColor;
$fixedButtonBoxBg: var(--fixedButtonBoxBg, $thirdDarkGreen);
$fixedButtonDiscountColor: var(--fixedButtonDiscountColor, $gold);
$policyCheckBoxTextColor: var(--policyCheckBoxTextColor, $white);

$plansTicketInactiveTextColor: var(--plansTicketInactiveTextColor, $white);
$plansTicketInactiveAccentTitleColor: var(--plansTicketInactiveAccentTitleColor, rgba($white, 0.8));
$plansTicketInactiveSubTitleColor: var(--plansTicketInactiveSubTitleColor, rgba($white, 0.5));
$plansTicketActiveSubTitleColor: var(--plansTicketActiveSubTitleColor, rgba($white, 1));
$plansTicketCheckBoxBorderColor: var(--plansTicketCheckBoxBorderColor, $white);

$radioPlansInactiveBg: transparent;
$radioPlansInactiveBorderColor: #1e3b48;
$radioPlansActiveBg: #2b5567;
$radioPlansActiveBorderColor: $white;
$radioPlansActiveTitleColor: #f2e6c4;
$radioPlansActiveColor: #27baa7;
$radioPlansColor: $white;
$radioPlansPriceActiveColor: #f27067;
$radioPlansWeekInactiveBg: #4a626d;

// manage

$manageCPPrimaryColor: $rumSwizzle;
$manageCPTitlePrimaryColor: $titleH2Color;
$manageCurrentPlanTitleWeight: $fwstb;
$manageCPSecondaryColor: $semiNeonGreen;
$manageBg: #101d23;
$manageAccentColor: #d0b894;
$manageWarningColor: #f27067;
$manageMainColor: $white;
$manageFeatureSubtitleColor: $manageMainColor;
$manageModalCloseIcon: #f2e6c4;
$manageModalBg: #2d5668;
$manageCheckmark: linear-gradient(134.16deg,
    #e5a87f 18.5%,
    #ecbf98 31.9%,
    #f7e6c4 56.46%,
    #e5a87f 92.92%);
$manageCheckboxBg: #1e3b48;
$manageCheckboxChecked: rgba(#e2cfa4, 0.3);
$manageTextareaBorder: $semiNeonGreen;
$manageTextareaBg: #152B35;
$manageCurrentPlanBg: $plansPrimaryBg image-set("src/screens/manage/images/plan.webp" 1x,
    "src/screens/manage/images/plan@2x.webp" 2x,
    "src/screens/manage/images/plan@3x.webp" 3x) no-repeat left top;
$manageDarkColor: rgba($white, 0.7);
$manageBorderColor: var(--manageBorderColor);
$manageTitleColor: $titleH1Color;
$manageSubtitleColor: #FAF6E8;
$manageBackArrowColor: $lightSand;
$manageBackButtonColor: $manageCheckmark;
$manageHeaderBgStyles: (
  'height': 100px,
  'background': image-set("src/screens/manage/images/header_bg@1x.webp" 1x,
    "src/screens/manage/images/header_bg@2x.webp" 2x,
    "src/screens/manage/images/header_bg@3x.webp" 3x) no-repeat center,
);
$manageContentContainer: (
  'border-radius': 0,
  'padding': 0,
  'margin-top': 20px,
);
$manageEmailCancelNoteBg: linear-gradient(134deg, rgba(35, 255, 232, 0.15) 2.33%, rgba(184, 97, 255, 0.15) 97.61%);
$manageEmailCancelTextColor: rgba($white, 0.7);
$manageEmailCancelEmailColor: #19cebb;

$manageFeatureSubtitleColor: rgba($white, 0.7);

$manageAlternativeButtonBorder: 1px solid $primaryColor;
$manageAlternativeButtonColor: white;
$manageAlternativeButtonBg: rgba(20, 213, 194, 0.10);
$manageButtonFontWeight: $fwstb;

$manageReasonButtonDisabledColor: $buttonBackground;
$manageAlternativeButtonFS: $fontSize20;

$manageReviewStarFill: $lowGray;
$manageReviewStarStroke: transparent;
$manageReviewStarFillActive: url('#gradientGold');
$manageReviewStarStrokeActive: transparent;

$managePlansBadgeColor: #f27067;

// terminate

$terminateBg: #101D23;
$terminateTitleColor: $gold;
$terminateTextColor: rgba(255, 255, 255, 0.70);
$terminateInputColor: rgba(255, 255, 255, 0.70);
$terminateInputBg: none;
$terminateConfirmBg: #142b35;
$terminateConfirmHeaderBg: #1e3b48;
$terminateConfirmBlur: linear-gradient(135.18deg,
    rgba(0, 255, 195, 0.7) 3.2%,
    rgba(78, 136, 222, 0.7) 99.69%);
$terminateConfirmHeaderColor: #f2e6c4;
$terminateConfirmWarningBg: #1e3b48;
$terminateConfirmWarningColor: rgba(255, 255, 255, 0.8);
$terminateConfirmFeatureBg: transparent;
$terminateConfirmFeatureBorder: #1e3b48;
$terminateConfirmFeatureTitleColor: #f2e6c4;
$terminateConfirmFeatureSubtitleColor: rgba(255, 255, 255, 0.7);
$terminateConfirmFeatureShadow: none;
$terminateConfirmFeaturesColor: #d0b894;
$terminateConfirmButtonColor: #ffffff;
$terminateConfirmButtonBorder: #19cebb;

// injected payment panel
$spmFontFamily: $fontFamilyPhilosopher;

// full payment modal
$fpmBackgroundColor: $thirdDarkGreen;
$fpmFontFamily: $fontFamilyPhilosopher;

$maxWidthDefault: 440px;

// questions
$questionTitleBgColor: var(--secondaryBg, $lowGray);
$questionTitleColor: var(--questionTitleColor);
$questionActiveTitleColor: var(--questionActiveTitleColor, $lightSand);
$questionActiveTitleBorderColor: var(--questionActiveTitleBorderColor, $lightSand);
$questionAnswerBorderColor: var(--questionAnswerBorderColor, $trans);
$questionSubTitleColor: var(--questionSubTitleColor, #2d2d2d);
$questionMiniRectangleAnswerFontWeight: $fwb;

// injected payment method
$injectedPaymentPrimaryColor: var(--injectedPaymentPrimaryColor, #499b92);
$injectedPaymentCheckBgColor: var(--injectedPaymentCheckBgColor, #ECF4F3);
$injectedPaymentMethodActiveColor: #14d5c2;
$injectedPaymentMethodCheckActiveColor: #14d5c2;
$injectedPaymentMethodColor: #1e3b48;
$injectedPaymentMethodArrowColor: #1e3b48;
$injectedPaymentMethodDark: flex;
$injectedPaymentMethodLight: none;

// trials left
$trialsLeftBadgeColor: var(--trialsLeftBadgeColor, $white);
$trialsLeftBadgeBg: var(--trialsLeftBadgeBg, #f27067);
$trialsLeftTextColor: #f27067;

// payment safe
$paymentSafeDark: inline-block;
$paymentSafeLight: none;
$paymentSafeColor: var(--paymentSafeColor, #faf6e8);

$paymentOverlay: $darkGrayBlue;

// users joined
$usersJoinedBorder: 1px solid var(--usersJoinedBorder, #2d5668);
$usersJoinedTextColor: var(--usersJoinedTextColor, #faf6e8);
$usersJoinedBoldColor: var(--usersJoinedBoldColor, #faf6e8);
$usersJoinedAvaBorderColor: var(--usersJoinedAvaBorderColor, #2d5668);

//progress
$progressPrevIconColor: var(--progressPrevIconColor, $lightSand);
$progressStateCountColor: var(--progressStateCountColor, $lightSand);
$progressFilledLineColor: var(--progressFilledLineColor, $darkNeonGreen);
$progressLineColor: var(--progressLineColor, $secondDarkGreen);

$progressCurrentTextColor: $gold;
$progressPrevLineColor: rgba($lightSand, 0.4);
$progressAccentLineColor: $progressPrevLineColor;
$progressAfterLineHeight: 1.2px;
$progressAccentColor: $lightSand;
$progressNotAccentColor: #6D766E;
$progressIconColor: #2D5668;

//terms-menu
$sideBarDarkButtonColor: var(--sideBarDarkButtonColor, $mintJulep);
$sideBarDarkBgColor: var(--sideBarDarkBgColor, #1e3b48);
$sideBarDarkBackDropBg: var(--sideBarDarkBackDropBg, rgba(21, 43, 53, 0.8));
$sideBarColor: var(--sideBarColor, $rumSwizzle);

//logo
$logoFontSize: var(--logoFontSize, $fontSize26);

//footer
$footerTextColor: var(--footerTextColor, $white);

//special offer
$soDiscountBlockBg: var(--soDiscountBlockBg);
$soDiscountImageBg: var(--soDiscountImageBg);
$soPrevDiscountTextColor: rgba($color: $white, $alpha: .6);
$soCurrentDiscountTextColor: $lightSand;
$soCurrentDiscountFontFamily: $fontFamilyPhilosopher;
$soDiscountBlockBorder: 1.5px solid $gold;
$soDiscountBlockBorderRadius: 15px;
$soDiscountBlockPadding: 20px 16px 16px;

$soReservedPriceBg: var(--soReservedPriceBg);

$soInfoAccentTextColor: var(--soInfoAccentTextColor);
$soInfoOfferTextBg: var(--soInfoOfferTextBg);

$soModalBg: $thirdDarkGreen;
$soModalIconClose: $lightSand;
$soModalDescriptionText: $white;

//success
$successPagePrimaryTextColor: var(--successPagePrimaryTextColor, rgba($rumSwizzle, 0.8));
$successPageBtnDividerLineColor: var(--successPageBtnDividerLineColor, rgba($rumSwizzle, 0.2));
$successPageSecondaryTextColor: var(--successPageSecondaryTextColor, $rumSwizzle);
$successPageContentBoxBgColor: var(--successPageContentBoxBgColor, $lowGray);

//autocomplete
$autocompleteBgColor: var(--autocompleteBgColor, $lowGray);
$autocompleteTextColor: var(--autocompleteTextColor, rgba($white, 0.8));
$autocompleteBorderColor: var(--autocompleteBorderColor, rgba($white, 0.2));

$autocompleteEmailBgColor: var(--autocompleteBgColor, $mainBgColor);
$autocompleteEmailBorderColor: var(--autocompleteBorderColor, #797979);
$autocompleteEmailTextColor: var(--autocompleteEmailTextColor, var(--primaryText));

//palm reading
$analyzingPalmReadingBgColor: var(--analyzingPalmReadingBgColor, #152b35);
$uploadPalmReadingBgColor: var(--uploadPalmReadingBgColor, $lowGray);

$palmReadingTextColor: var(--palmReadingTextColor, $white);
$palmReadingAnalyzingTextColor: #D0B894;

$palmReadingPrivacyTextColor: var(--palmReadingPrivacyTextColor, $textColor);
$palmReadingDisclaimerColor: var(--palmReadingDisclaimerColor, $lightSand);

$palmReadingScanAnimBg: var(--palmReadingScanAnimBg,
    linear-gradient(0deg,
      #18cfbc 0%,
      rgba(20, 213, 194, 0.487899) 50.4%,
      rgba(22, 209, 190, 0.0001) 100%));

$palmReadingPhotoInnerBgColor: var(--palmReadingPhotoInnerBgColor, $lightNeonGreen);
$palmReadingPhotoOuterBgColor: var(--palmReadingPhotoOuterBgColor, $white);

$palmReadingProgressColor: var(--palmReadingProgressColor,
    linear-gradient(90deg,
      #e5a87f 0.03%,
      #ecbf98 18.03%,
      #f7e6c4 51.02%,
      #e5a87f 100.02%),
    linear-gradient(52deg,
      #00eedb -13.54%,
      #0082df 91.08%));
$palmReadingProgressPadding: var(--palmReadingProgressPadding, 3px);
$palmReadingProgressBgColor: var(--palmReadingProgressBgColor, #356377);

$palmReadingWelcomeTitleStyles: (
);
$palmReadingWelcomeSubtitleStyles: (
  color: $textColor
);
$palmReadingWelcomeButtonTitleStyles: (
);
$palmReadingProgressTextStyles: (
  'color': var(--palmReadingProgressTextColor, $rumSwizzle),
);
$palmReadingBottomBgStyles: (
  'background-image': image-set("src/screens/quiz/palm-reading/images/loading-bg.webp" 1x, "src/screens/quiz/palm-reading/images/loading-bg@2x.webp" 2x, "src/screens/quiz/palm-reading/images/loading-bg@3x.webp" 3x),
  'width': 100%,
  'height': 100%,
  'position': absolute,
  'bottom': -10px,
  'background-repeat': no-repeat,
  'background-size': contain,
  'background-position': center,
);
$palmReadingBackButtonStyles: (
  'border-radius': 50%,
  'background-color': rgba($mineShaft, 0.6),
  'box-shadow': 0 1px 10px rgba(183, 181, 170, 0.4),
);

$palmReadingLinkColor: var(--primaryColor);
$palmReadingButtonBg: var(--primaryColor);

//error modal

$errorModalFontFamily: $fontFamilyPhilosopher;
$errorModalTitleColor: $mintJulep;

$errorModalBgColor: var(--errorModalBgColor, #09968f);
$errorModalCloseBtnBgColor: var(--errorModalCloseBtnBgColor, $lowGray);

$errorModalSkipBtnColor: var(--errorModalSkipBtnColor, $white);
//upsales
$upsaleBgColor: var(--upsaleBgColor, $deepDarkGreen);

$upsaleProgressMainColor: var(--upsaleProgressMainColor, $lightSand);
$upsaleProgressSecondLineColor: var(--upsaleProgressSecondLineColor, $lightSand);
$upsaleProgressLineOpacity: var(--upsaleProgressLineOpacity, 0.4);
$upsaleProgressPointBgColor: var(--upsaleProgressPointBgColor, #717b74);
$upsaleProgressInactiveColor: var(--upsaleProgressInactiveColor, rgba($lightSand, 0.4));

$upsaleListPointBgColor: var(--upsaleListPointBgColor, #c583fa);
$upsaleListTextColor: var(--upsaleListTextColor, $white);

$upsaleActivePriceColor: var(--upsaleActivePriceColor, $lightSand);
$upsaleNotActivePriceColor: var(--upsaleNotActivePriceColor, rgba($white, 0.8));
$upsaleNotActivePriceDecorationColor: var(--upsaleNotActivePriceDecorationColor, currentColor);

$upsaleSkipTextColor: var(--upsaleSkipTextColor, rgba($white, 0.7));
$upsaleFootnoteTextColor: var(--upsaleFootnoteTextColor, rgba($white, 0.4));

// contact us modal
$contactUsModalBg: var(--contactUsModalBg, #111d23);
$contactUsModalTitleColor: var(--contactUsModalTitleColor, #f2e6c4);
$contactUsModalSubTitleColor: var(--contactUsModalSubTitleColor, #e1d7b9);
$contactUsModalFieldColor: var(--contactUsModalFieldColor, #428f9e);
$contactUsModalActiveColor: var(--contactUsModalActiveColor, rgb(20, 213, 194));
$contactUsModalSecondaryBg: var(--contactUsModalSecondaryBg, #111d23);
$contactUsModalTextColor: var(--contactUsModalTextColor, #fff);
$contactUsModalDoneIcon: var(--contactUsModalDoneIcon, #111d23);

// my account
$myAccountBg: var(--myAccountBg, #1E3B48);
$myAccountTitleColor: var(--myAccountTitleColor, #f2e6c4);
$myAccountSubTitleColor: var(--myAccountSubTitleColor, rgba($white, 0.80));
$myAccountTextColor: var(--myAccountTextColor, rgba($white, 0.70));
$myAccountHighlightTextColor: var(--myAccountHighlightTextColor, $white);
$myAccountArrowBtnColor: $myAccountTitleColor;
$myAccountInfoBlockBg: var(--myAccountInfoBlockBg, #152B35);

$sideBarDarkBgColor: var(--sideBarDarkBgColor, #1e3b48);

// shipping flow
$shippingFlowText: $rumSwizzle;

// reset password
$resetPasswordSuccessTextColor: #D0B894;
$resetPasswordErrorColor: #F27067;
$resetPasswordBorderColor: #F2E6C4;
$resetPasswordPlaceholderColor: rgba(242, 230, 196, 0.50);
$resetPasswordFieldTextColor: $white;
$resetPasswordTitleColor: var(--coloredText);
$resetPasswordTextColor: $white;
$resetPasswordSuccessPopupBg: $thirdDarkGreen;
$resetPasswordBlurStyles: (
  'margin': 0 auto,
  'width': 375px,
  'height': 375px,
  'border-radius': 200px,
  'opacity': 0.4,
  'background': radial-gradient(50% 50% at 50% 50%, rgba(25, 206, 187, 0.70) 0%, rgba(25, 206, 187, 0.00) 100%),
  'filter': blur(30px),
  'position': absolute,
  'top': -180px,
  'left': 0,
  'right': 0,
);
$resetPasswordFieldStyles: (
  'width': 100%,
  'border-radius': 0px,
  'border': none,
  'border-bottom': 1px solid $resetPasswordPlaceholderColor,
  'background': transparent,
  'padding': 0 40px 5px 0,
  'font-size': $fontSize20,
  'font-weight': $fwn,
  'line-height': normal,
  'color': $resetPasswordFieldTextColor,
  'caret-color': $resetPasswordFieldTextColor,
  'position': relative,
);
$resetPasswordPlaceholderStyles: (
  'color': $resetPasswordPlaceholderColor,
);
$resetPasswordErrorStyles: (
  'border-bottom': 1px solid #F27067,
);

// create account
$accountAlreadyExistsModalOverlayBg: $paymentActionModalOverlayBgColor;
$accountAlreadyExistsModalBg: $paymentActionModalBgColor;

$createAccountEmailExistPopupTextColor: rgba(242, 230, 196, 0.80);
$createAccountTitleColor: $primaryText;
$createAccountBorderColor: rgba($lightSand, 0.5);
$createAccountActiveBorderColor: $lightSand;
$createAccountAsteriskColor: $primaryColor;
$createAccountErrorColor: #F27067;
$createAccountInputIconsColor: rgba($lightSand, 0.5);
$createAccountInputPlaceholderColor: rgba($lightSand, 0.5);
$createAccountDividerColor: $darkTeal;
$createAccountDisabledFieldOpacity: 0.5;
$createAccountTitleStyles: (
  'margin-top': 4vh,
  'color': $titleH2Color,
  'font-size': 35px,
  'font-weight': $fwstb,
);
$createAccountSubtitleStyles: (
  'color': $gold,
  'font-size': $fontSize15,
  'line-height': 1.6
);
$createAccountInputStyles: (
  'padding': 15px 40px 5px 0,
  'font-size': $fontSize20,
  'font-weight': $fwn,
  'line-height': normal,
  'color': $white,
  'background': transparent,
  'border': none,
  'border-radius': initial,
  'border-bottom': 2px solid $createAccountBorderColor,
);
$createAccountInputNameStyles: (
  'display': none,
  'font-size': $fontSize14,
  'font-weight': $fwstb,
  'margin-bottom': 5px,
);