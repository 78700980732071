@import 'src/styles';

.welcome {
  max-width: $maxWidthDefault;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;

  &.light,
  &.dark {
    width: 100%;

    .welcome__title {
      text-align: left;
      font-size: $fontSize26;
      width: 100%;
      max-width: $maxWidthDefault;
      padding: 0 25px;

      @media (max-height: 600px) {
        font-size: $fontSize24;
      }
    }
  }

  .buttons_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
  }

  &__title {
    margin: 4vh 0 1vh;
    padding: 0 20px;
    font-size: $fontSize26;
    line-height: 1.15;
    font-weight: $fwstb;
    text-align: center;

    @include map-styles($palmReadingWelcomeTitleStyles);

    & b {
      color: $accentTextColor;
    }

    @media (max-height: 600px) {
      margin: 2vh 0 5px;
      font-size: $fontSize26;
    }
  }

  .subtitle {
    margin: 0 auto;
    padding-top: 10px;
    width: 90%;

    @media (max-height: 600px) {
      width: 95%;
      padding: 0;
      margin-bottom: 10px;
    }

    @include map-styles($palmReadingWelcomeSubtitleStyles);
  }

  .imageWrapper {
    position: relative;
    width: fit-content;
  }

  &__image {
    max-width: 100%;
    width: auto;
    max-height: 245px;
    height: auto;
  }

  .button {
    margin-bottom: 10px;

    background-color: $palmReadingButtonBg;

    .btn_title {
      @include map-styles($palmReadingWelcomeButtonTitleStyles);
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    margin-left: 15px;
  }

  .text {
    color: $white;
    font-family: $fontFamilyPhilosopher;
    font-size: $fontSize18;
    font-style: normal;
    font-weight: $fwstb;
    line-height: normal;
  }

  .link {
    font-weight: $fwb;
    line-height: $fontSize22;
    text-decoration: none;

    color: $palmReadingLinkColor;
  }

  .disclaimer {
    max-width: 292px;
    font-family: $fontFamily;
    font-size: $fontSize12;
    font-weight: 400;
    line-height: $fontSize17;
    letter-spacing: 0px;
    text-align: center;
    color: $palmReadingDisclaimerColor;
  }

  .privacy_disclaimer {
    padding-left: 5px;
    width: 80%;
    font-size: $fontSize14;
    line-height: 1.4;
    color: $palmReadingPrivacyTextColor;
    text-align: center;

    @media (max-height: 650px) {
      width: 90%;
      font-size: $fontSize12;
    }
  }
}